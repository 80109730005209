import React from 'react'
import Multiagente from '../../templates/multiagente'

const MultiagenteEsPage = () => {
    return (
        <Multiagente lang="en">
        </Multiagente>
    )
}

export default MultiagenteEsPage
